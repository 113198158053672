import { MouseEvent } from 'react';
import { useHlpRouter } from '@hanwhalife/reactjs/router';
import { Button, Feedback, IconColorProblem } from '@hanwhalife/shared-libs/design-system';
import { StyledError } from '@hanwhalife/styled/_shared';
import { CsBanner } from '@hanwhalife/styled/_shared/components/ui/cs-banner';

export const PageNotFound = ({ onMoveRootPage }: { onMoveRootPage?: () => void }) => {
  const router = useHlpRouter();

  const handleRootPage = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    router.push('/');
  };

  return (
    <>
      <StyledError>
        <Feedback
          icon={() => <IconColorProblem width="64" height="64" aria-hidden="true" />}
          title={<div className="typography-title3">페이지를 찾을 수 없어요</div>}
          bodyContents={
            <p className="typography-body2 text-body3">
              찾으시는 주소가 잘못 입력되었거나 변경 혹은 삭제되어 주소를 사용할 수 없어요. <br />
              입력하신 페이지의 주소를 다시 한번 확인해주세요.
            </p>
          }
          className="fill-feedback text-center"
        />

        {/* 고객센터 배너 */}
        <CsBanner type="product" />

        <Button tagName="a" href="/" size="large" block onClick={onMoveRootPage ?? handleRootPage} className="mt-8">
          홈으로 가기
        </Button>
      </StyledError>
    </>
  );
};
